import { useCallback, useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "react-responsive";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { MobileScreenWidth } from "../../../../constants";
import { SnackContext } from "../../../providers/SnackProvider";
import { WatchListing } from "../../../models/WatchListing";
import { loggingService } from "../../../../utils/logging/logging";
import { FileUploadInfo } from "../../fileupload/FileUploadItem";
import DropDown from "../../dropDown/DropDown";
import { useFetchWrapper } from "../../../hooks/useFetchWrapper";
import ShowroomContainer from "./Showroom";
import DetailsContainer from "./Details";
import ConditionsContainer from "./Conditions";
import {
    createAttachment,
    getBatchDirectUploadUrl,
    deleteAttachment,
    updateAttachment,
} from "../../../services/auction-service";
import BackDrop from "../../backDrop/BackDrop";

import styles from "./EditImages.module.scss";

const { log } = loggingService("app:EditImagesDialog");

interface EditImagesDialogProps {
    watchDetails: WatchListing;
    isOpen: boolean;
    onClose: () => void;
    updateListingImages: (watchDetails: WatchListing) => void;
}

const imagesTypes = ["Showroom", "Details", "Condition"];

const EditImagesDialog = ({
    isOpen,
    onClose,
    watchDetails,
    updateListingImages,
}: EditImagesDialogProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const showSnackbar = useContext(SnackContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showroomAttach, setShowroomAttach] = useState<FileUploadInfo[]>([]);
    const [detailsAttach, setDetailsAttach] = useState<FileUploadInfo[]>([]);
    const [conditionsAttach, setConditionsAttach] = useState<FileUploadInfo[]>(
        [],
    );
    const [imageType, setImageType] = useState("Showroom");
    const [detailsImages, setDetailsImages] = useState<any[]>([]);
    const [conditionImages, setConditionImages] = useState<any[]>([]);
    const [rearrange, setRearrange] = useState({
        conditions: false,
        details: false,
    });
    const { postWithToken } = useFetchWrapper();

    useEffect(() => {
        setDetailsImages(watchDetails.images);
        setConditionImages(watchDetails.conditionDetails.images);
    }, [watchDetails]);

    const handleClose = useCallback(() => {
        setShowroomAttach([]);
        setDetailsAttach([]);
        setConditionsAttach([]);
        setImageType("Showroom");
        setIsLoading(false);
        onClose();
    }, [onClose]);

    const updateDetails = useCallback(
        async (newImages: {
            showroom: any[] | undefined;
            details: any[] | undefined;
            conditions: any[] | undefined;
        }) => {
            const newWatchDetails = { ...watchDetails };
            if (newImages?.showroom?.length) {
                newWatchDetails.showroomImage = newImages.showroom[0];
            }
            if (newImages?.details?.length) {
                newWatchDetails.images = [
                    ...watchDetails.images,
                    ...newImages?.details,
                ];
            }
            if (newImages?.conditions?.length) {
                newWatchDetails.conditionDetails.images = [
                    ...watchDetails.conditionDetails.images,
                    ...newImages?.conditions,
                ];
            }
            updateListingImages(newWatchDetails);
        },
        [updateListingImages, watchDetails],
    );

    const onDelete = useCallback(
        async (type: string, images: any[]) => {
            if (type === "details") {
                updateListingImages({ ...watchDetails, images });
            } else {
                updateListingImages({
                    ...watchDetails,
                    conditionDetails: {
                        ...watchDetails.conditionDetails,
                        images,
                    },
                });
            }
            setIsLoading(false);
            showSnackbar("Your image was successfully deleted", {
                alertSeverity: "success",
            });
        },
        [updateListingImages, watchDetails, showSnackbar],
    );

    const uploadImages = useCallback(
        async (files: FileUploadInfo[], itemId: string, category: string) => {
            try {
                setIsLoading(true);
                const { url, token } = await getBatchDirectUploadUrl();
                if (!url || !token) throw new Error("No upload URL, Token");

                const newImages: any[] = [];
                for (const file of files) {
                    const formData = new FormData();
                    formData.append("file", file.file);
                    const upload = await postWithToken(
                        url,
                        token,
                        formData,
                        true,
                    );
                    const res = await upload.json();
                    if (res.success) {
                        const image = await createAttachment(
                            itemId,
                            res.result,
                            category,
                        );
                        newImages.push(image);
                    } else {
                        throw new Error(
                            "Your attachments didn't upload successfully",
                        );
                    }
                }
                return newImages;
            } catch (error: any) {
                const errorMessage =
                    error instanceof Error
                        ? error.message
                        : "An error occurred. Please try again or contact us if the problem persists.";
                setIsLoading(false);
                showSnackbar(errorMessage, { alertSeverity: "error" });
            }
        },
        [postWithToken, showSnackbar],
    );

    const rearrangeImages = useCallback(
        async (images: any[], category: string) => {
            try {
                const newImages: any[] = [];
                for (const image of images) {
                    const newImage = await updateAttachment(
                        watchDetails.itemId,
                        image.id,
                        {
                            category: image.category,
                            sortOrder: image.sortOrder,
                        },
                    );
                    newImages.push(newImage);
                }
                if (category === "details") {
                    updateListingImages({ ...watchDetails, images: detailsImages });
                } else {
                    updateListingImages({
                        ...watchDetails,
                        conditionDetails: {
                            ...watchDetails.conditionDetails,
                            images: conditionImages,
                        },
                    });
                }
            } catch (error) {
                log("Error rearrange images", error);
                return showSnackbar("Failed to rearrange images", {
                    alertSeverity: "error",
                });
            }
        },
        [
            watchDetails,
            showSnackbar,
            updateListingImages,
            detailsImages,
            conditionImages,
        ],
    );

    const onSave = useCallback(async () => {
        let allImages: {
            showroom: any[] | undefined;
            details: any[] | undefined;
            conditions: any[] | undefined;
        } = {
            showroom: [],
            details: [],
            conditions: [],
        };
        if (showroomAttach.length && watchDetails.showroomImage) {
            const images: any[] | undefined = await uploadImages(
                showroomAttach,
                watchDetails.itemId,
                "showroom",
            );
            if (watchDetails.showroomImage?.id) {
                await deleteAttachment(
                    watchDetails.itemId,
                    watchDetails.showroomImage.id,
                );
            }
            allImages = { ...allImages, showroom: images };
        }
        if (showroomAttach.length && !watchDetails.showroomImage) {
            const images: any[] | undefined = await uploadImages(
                showroomAttach,
                watchDetails.itemId,
                "showroom",
            );
            allImages = { ...allImages, showroom: images };
        }
        if (detailsAttach.length) {
            const images: any[] | undefined = await uploadImages(
                detailsAttach,
                watchDetails.itemId,
                "details",
            );
            allImages = { ...allImages, details: images };
        }
        if (conditionsAttach.length) {
            const images: any[] | undefined = await uploadImages(
                conditionsAttach,
                watchDetails.itemId,
                "condition",
            );
            allImages = { ...allImages, conditions: images };
        }
        if (rearrange.details) {
            const images = detailsImages.filter(
                (item) =>
                    item.sortOrder !==
                    watchDetails.images.find((image) => image.id === item.id)
                        ?.sortOrder,
            );
            await rearrangeImages(images, "details");
        }
        if (rearrange.conditions) {
            const images = conditionImages.filter(
                (item) =>
                    item.sortOrder !==
                    watchDetails.conditionDetails.images.find(
                        (image) => image.id === item.id,
                    )?.sortOrder,
            );
            await rearrangeImages(images, "condition");
        }
        await updateDetails(allImages);
        handleClose();
        return showSnackbar("Your images were successfully added", {
            alertSeverity: "success",
        });
    }, [
        showroomAttach,
        uploadImages,
        watchDetails,
        conditionsAttach,
        detailsAttach,
        rearrange,
        detailsImages,
        conditionImages,
        handleClose,
        rearrangeImages,
        updateDetails,
        showSnackbar,
    ]);
    const detailsProps = {
        itemId: watchDetails.itemId,
        detailsImages,
        detailsAttach,
        setDetailsImages,
        setDetailsAttach,
        onDelete,
        setRearrange,
        setIsLoading,
    };
    const conditionsProps = {
        itemId: watchDetails.itemId,
        conditionImages,
        conditionsAttach,
        setConditionImages,
        setConditionsAttach,
        onDelete,
        setRearrange,
        setIsLoading,
    };
    return (
        <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={handleClose}
                className={isDesktop ? styles.root : styles.rootMobile}
                scroll="paper"
            >
                <DialogTitle className={styles.dialogTitleContainer}>
                    <Typography className={styles.titleText}>
                        Edit Watch Images
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        onClick={handleClose}
                        sx={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <CloseIcon className={styles.closeIcon} />
                    </IconButton>
                </DialogTitle>

                <DialogContent className={styles.contentContainer}>
                    <Stack direction="column">
                        <Stack
                            direction="column"
                            className={styles.watchImageTypeContainer}
                        >
                            <DropDown
                                id="imageTypeInput"
                                label="Watch Images for"
                                placeholder="e.g. Showroom"
                                disabled={false}
                                data={imagesTypes}
                                setState={setImageType}
                                value={imageType}
                            />
                            {imageType === "Showroom" && (
                                <Typography className={styles.showroomWarn}>
                                    <span style={{ color: "red" }}>FYI: </span>
                                    You can upload one image for the showroom.
                                </Typography>
                            )}
                        </Stack>
                        {imageType === "Showroom" ? (
                            <ShowroomContainer
                                {...{
                                    showroomAttach,
                                    setShowroomAttach,
                                    showroomImage:
                                        watchDetails.showroomImage?.mediaUrl,
                                }}
                            />
                        ) : imageType === "Details" ? (
                            <DetailsContainer {...detailsProps} />
                        ) : (
                            <ConditionsContainer {...conditionsProps} />
                        )}
                        <Stack direction="row" className={styles.saveContainer}>
                            <Button
                                className={styles.saveButton}
                                variant="contained"
                                disableElevation
                                onClick={onSave}
                            >
                                <Typography className={styles.saveText}>
                                    Save
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
                <BackDrop open={isLoading} />
            </Dialog>
        </DndProvider>
    );
};

export default EditImagesDialog;
