import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from './WithdrawListingDialog.module.scss';
import { ChangeEvent, useCallback, useState } from "react";

interface WithdrawListingDialogProps {
    isOpen: boolean;    
    onOk: (reason: string) => void;
    onCancel: () => void;
}

export const WithdrawListingDialog = ({isOpen, onOk, onCancel}: WithdrawListingDialogProps) => {
    const [reason, setReason] = useState('');

    const onValueChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setReason(event.target.value);
    }, []);

    const handleCancelClicked = useCallback(() => {
        setReason('');
        onCancel();
    }, [onCancel]);

    return (
        <Dialog fullWidth={true} open={isOpen} onClose={onCancel} className={styles.root}>
            <DialogTitle className={styles.dialogTitleContainer}>
                <Typography className={styles.title}>Confirm Withdrawal</Typography>
                
                <IconButton aria-label="Close" onClick={onCancel} sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}>
                    <CloseIcon className={styles.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.contentContainer}>
                <Stack direction='column'>
                    <Typography className={styles.valueField}>Please enter a reason for withdrawing the listing:</Typography>
                    <TextField className={styles.valueField} value={reason} onChange={onValueChanged} variant='outlined' fullWidth />
                </Stack>
                <Stack direction='row' className={styles.buttonContainer}>
                    <Button className={styles.button} variant="outlined" onClick={() => onOk(reason)} disabled={!reason}>
                        <Typography className={styles.buttonText}>OK</Typography>
                    </Button>
                    <Button className={styles.button} variant="outlined" onClick={handleCancelClicked}>
                        <Typography className={styles.buttonText}>Cancel</Typography>
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}