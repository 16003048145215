import { useCallback } from "react";
import { Button, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import GoldSwitch from "../../../switch/Switch";
import DropDown from "../../../dropDown/DropDown";
import { MobileScreenWidth } from "../../../../../constants";
import styles from "./AuctionSell.module.scss";

interface sellInfoProps {
    hasModelNumber: boolean;
    hasYear: boolean;
    hasBox: boolean;
    hasPapers: boolean;
    year: string;
    modelNumber: string;
}

export interface WatchDetailsProps {
    user?: { email_verified: boolean };
    sellInfo: sellInfoProps;
    brands: any[];
    watchBrand: string;
    models: any[];
    variants: any[];
    watchModel: string;
    modelNumber: string;
    handleSubmit: () => void;
    setWatchBrand: React.Dispatch<React.SetStateAction<any>>;
    setWatchModel: React.Dispatch<React.SetStateAction<any>>;
    setModelNumber: React.Dispatch<React.SetStateAction<any>>;
    setSellInfo: React.Dispatch<React.SetStateAction<any>>;
}

const WatchDetails = ({
    user,
    brands,
    setWatchBrand,
    watchBrand,
    models,
    variants,
    watchModel,
    setWatchModel,
    setModelNumber,
    modelNumber,
    sellInfo,
    setSellInfo,
    handleSubmit,
}: WatchDetailsProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const toggleModelNumber = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
            setSellInfo((prev: any) => {
                return {
                    ...prev,
                    hasModelNumber: newValue,
                    modelNumber: newValue ? prev.modelNumber : "",
                };
            });
        },
        [setSellInfo],
    );

    const toggleYear = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
            setSellInfo((prev: any) => {
                return {
                    ...prev,
                    hasYear: newValue,
                    year: newValue ? prev.year : "",
                };
            });
        },
        [setSellInfo],
    );

    const togglePapers = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
            setSellInfo((prev: any) => {
                return {
                    ...prev,
                    hasPapers: newValue,
                };
            });
        },
        [setSellInfo],
    );

    const toggleBox = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
            setSellInfo((prev: any) => {
                return {
                    ...prev,
                    hasBox: newValue,
                };
            });
        },
        [setSellInfo],
    );

    return (
        <div style={{ gridColumn: 1 }} className={styles.watchDetailsContainer}>
            <div
                style={{ gridRow: 1, gridColumn: 1 }}
                className={styles.watchBrandContainer}
            >
                <DropDown
                    id="watchBrandInput"
                    label="Brand"
                    placeholder="e.g. Rolex"
                    disabled={!user || !user?.email_verified}
                    data={brands}
                    onChange={value => setWatchBrand(value)}
                    value={watchBrand}
                />
            </div>

            <div
                style={{ gridRow: 1, gridColumn: 2 }}
                className={styles.watchModelContainer}
            >
                <DropDown
                    id="watchModelInput"
                    label="Model"
                    disabled={!user || !user?.email_verified}
                    placeholder="e.g. Daydate"
                    data={models}
                    onChange={value => setWatchModel(value)}
                    value={watchModel}
                />
            </div>

            <div
                style={{ gridRow: 2, gridColumn: 1 }}
                className={styles.watchModelNumberContainer}
            >
                <Typography className={styles.questionText}>
                    Do you know the model number?
                </Typography>
                <Stack direction="row" className={styles.switchContainer}>
                    <Typography
                        className={styles.switchText}
                        style={{ marginRight: 8 }}
                    >
                        No
                    </Typography>
                    <GoldSwitch
                        checked={sellInfo.hasModelNumber}
                        onChange={toggleModelNumber}
                        disabled={!user || !user?.email_verified}
                    />
                    <Typography
                        className={styles.switchText}
                        style={{ marginLeft: 8 }}
                    >
                        Yes
                    </Typography>
                </Stack>
                {sellInfo.hasModelNumber && (
                     <DropDown
                        id="watchVariantInput"
                        label=""
                        disabled={!user || !user?.email_verified}
                        placeholder="e.g. 116500LN"
                        data={variants}
                        setState={setModelNumber}
                        value={modelNumber}
                        sx={{ marginTop: 3 }}
                    />
                )}
            </div>

            <div
                style={{ gridRow: 2, gridColumn: 2 }}
                className={styles.watchYearContainer}
            >
                <Typography className={styles.questionText}>
                    Do you know the year?
                </Typography>
                <Stack direction="row" className={styles.switchContainer}>
                    <Typography
                        className={styles.switchText}
                        style={{ marginRight: 8 }}
                    >
                        No
                    </Typography>
                    <GoldSwitch
                        checked={sellInfo.hasYear}
                        onChange={toggleYear}
                        disabled={!user || !user?.email_verified}
                    />
                    <Typography
                        className={styles.switchText}
                        style={{ marginLeft: 8 }}
                    >
                        Yes
                    </Typography>
                </Stack>
                {sellInfo.hasYear && (
                    <TextField
                        value={sellInfo.year}
                        className={styles.watchYearInput}
                        fullWidth
                        id="watchYearInput"
                        label="Watch Year"
                        variant="standard"
                        onChange={(e) =>
                            setSellInfo({
                                ...sellInfo,
                                year: e.target.value,
                            })
                        }
                        disabled={!user || !user?.email_verified}
                    />
                )}
            </div>

            <div
                style={{ gridRow: 3, gridColumn: 1 }}
                className={styles.papersContainer}
            >
                <Typography className={styles.questionText}>
                    Do you have the original papers?
                </Typography>
                <Stack direction="row" className={styles.switchContainer}>
                    <Typography
                        className={styles.switchText}
                        style={{ marginRight: 8 }}
                    >
                        No
                    </Typography>
                    <GoldSwitch
                        checked={sellInfo.hasPapers}
                        onChange={togglePapers}
                        disabled={!user || !user?.email_verified}
                    />
                    <Typography
                        className={styles.switchText}
                        style={{ marginLeft: 8 }}
                    >
                        Yes
                    </Typography>
                </Stack>
            </div>

            <div
                style={{ gridRow: 3, gridColumn: 2 }}
                className={styles.boxContainer}
            >
                <Typography className={styles.questionText}>
                    Do you have the original watch box?
                </Typography>
                <Stack direction="row" className={styles.switchContainer}>
                    <Typography
                        className={styles.switchText}
                        style={{ marginRight: 8 }}
                    >
                        No
                    </Typography>
                    <GoldSwitch
                        checked={sellInfo.hasBox}
                        onChange={toggleBox}
                        disabled={!user || !user?.email_verified}
                    />
                    <Typography
                        className={styles.switchText}
                        style={{ marginLeft: 8 }}
                    >
                        Yes
                    </Typography>
                </Stack>
            </div>

            <div
                style={{
                    gridRow: 4,
                    gridColumn: "1 / span 2",
                }}
                className={
                    isDesktop
                        ? styles.submitContainer
                        : styles.submitContainerMobile
                }
            >
                <Button
                    variant="outlined"
                    className={styles.submitButton}
                    onClick={() => handleSubmit()}
                    disabled={!user || !user?.email_verified}
                >
                    <Typography className={styles.submitText}>
                        Submit for Valuation
                    </Typography>
                </Button>
            </div>
        </div>
    );
};

export default WatchDetails;
