import {
    Button,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    forwardRef,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
    useEffect,
} from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import AttachFileIcon from '@mui/icons-material/AttachFile';
import styles from "./EditableWatchSummaryMobile.module.scss";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { InitDetail } from "lightgallery/lg-events";
import { LightGallery as LightGalleryPlugin } from "lightgallery/lightgallery";
import { LightGalleryLicense } from "../../../../constants";
import { AuthContext } from "../../../providers/AuthProvider";
import Parser from "html-react-parser";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";
import { getDisplayDate } from "../../../../utils/date/dateUtils";
import EndTimer from "../../endTimer/EndTimer";
import { ActionItem, getActions, getStatus } from "./EditableWatchSummary.utils";
import { WatchListing } from "../../../models/WatchListing";
import ConditionTooltip from "../../conditionTooltip/ConditionTooltip";
import EditImagesDialog from "../../dialogs/images/EditImagesDialog";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker } from "@mui/x-date-pickers";
import { SnackContext } from "../../../providers/SnackProvider";
import { WatchDetailsDialog } from "../../dialogs/watchDetails/WatchDetailsDialog";
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import { ProductGroups } from "../../../models/ProductInfo";
import DropDown from "../../dropDown/DropDown";
import { useNavigate } from "react-router-dom";
import { WithdrawListingDialog } from "../../dialogs/withdrawListing/WithdrawListingDialog";
// import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const inputRequired = "Input required";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="£"
            />
        );
    },
);

export interface EditableWatchSummaryMobileProps {
    watchDetails: WatchListing;
    updateListing: (
        originalListing: WatchListing,
        updatedListing: WatchListing,
    ) => Promise<boolean>;
    updateListingImages: (watchDetails: WatchListing) => void;
    startDrafting: (listing: WatchListing) => Promise<boolean>;
    rejectProposal: (listing: WatchListing) => Promise<boolean>;
    rejectDraft: (listing: WatchListing) => Promise<boolean>;
    withdrawListing: (
        listing: WatchListing,
        reason: string,
    ) => Promise<boolean>;
    requestApproval: (listing: WatchListing) => Promise<boolean>;
    updateToComingSoon: (listing: WatchListing) => Promise<boolean>;
    refreshListings: () => void;
    brands: any[];
    allProducts: ProductGroups;
}

const EditableWatchSummaryMobile = ({
    watchDetails,
    updateListing,
    updateListingImages,
    startDrafting,
    rejectProposal,
    rejectDraft,
    withdrawListing,
    requestApproval,
    updateToComingSoon,
    refreshListings,
    brands,
    allProducts,
}: EditableWatchSummaryMobileProps) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const showSnackbar = useContext(SnackContext);
    const [showCondition, setShowCondition] = useState(true);
    // const actions = useMemo(() => getActions(watchDetails, user), [user, watchDetails]);
    const actions = useMemo(
        () => getActions(watchDetails, user),
        [user, watchDetails],
    );
    const lightGalleryRef = useRef<LightGalleryPlugin>();
    const lightGalleryConditionsRef = useRef<LightGalleryPlugin>();
    const allImages = useMemo(
        () => [
            ...(watchDetails.showroomImage ? [watchDetails.showroomImage] : []),
            ...watchDetails.images,
        ],
        [watchDetails],
    );
    const [models, setModels] = useState<any[]>([]);
    const [variants, setVariants] = useState<any[]>([]);
    const [showWithdrawListingDialog, setShowWithdrawListingDialog] =
        useState(false);
    const [showEditImagesDialog, setShowEditImagesDialog] = useState(false);
    const [listingToEdit, setListingToEdit] = useState<WatchListing>();
    const [specificEdit, setSpecificEdit] = useState(false);
    const canRequestApproval = useMemo(() => {
        return (
            watchDetails.listingStatus === "Draft" &&
            watchDetails.year &&
            watchDetails.summary &&
            watchDetails.specification.lot &&
            watchDetails.specification.condition &&
            watchDetails.specification.box &&
            watchDetails.specification.papers &&
            watchDetails.auctionStartDate &&
            watchDetails.auctionEndDate &&
            watchDetails.auctionStartDate < watchDetails.auctionEndDate
        );
    }, [watchDetails]);

    const handleWithdrawListing = useCallback(() => {
        setShowWithdrawListingDialog(true);
    }, []);

    const onOkWithdrawListingDialog = useCallback(
        (reason: string) => {
            withdrawListing(watchDetails, reason);
        },
        [watchDetails, withdrawListing],
    );

    const onCancelWithdrawListingDialog = useCallback(() => {
        setShowWithdrawListingDialog(false);
    }, []);

    const onCloseEditImagesDialog = useCallback(() => {
        setShowEditImagesDialog(false);
    }, []);

    const [previewWatchDetails, setPreviewWatchDetails] =
        useState<WatchInfoBuyDetails>();
    const [showPreviewWatchDetailsDialog, setShowPreviewWatchDetailsDialog] =
        useState(false);
    const onClosePreviewWatchDetailsDialog = useCallback(() => {
        setShowPreviewWatchDetailsDialog(false);
    }, []);

    const displayPreview = useCallback((listing: WatchListing) => {
        const watchDetails: WatchInfoBuyDetails = {
            id: listing.listingId ?? "",
            brand: listing.brand ?? "",
            model: listing.model ?? "",
            year: listing.year ?? "",
            bidsError: listing.bidsError ?? "",
            bids: listing.bids ?? 0,
            auctionStartDate: listing.auctionStartDate,
            auctionEndDate: listing.auctionEndDate,
            auctionStartDateDescription: listing.auctionStartDateDescription,
            auctionEndDateDescription: listing.auctionEndDateDescription,
            createdOn: listing.createdOn,
            withdrawnOn: listing.withdrawnOn,
            withdrawnReason: listing.withdrawnReason,
            reservePrice: listing.reservePrice,
            currentBid: listing.currentBid ?? 0,
            currentBidUser: listing.currentBidUser ?? "",
            isFavourite: listing.isFavourite,
            savedAsFavouriteCount: listing.savedAsFavouriteCount ?? 0,
            sellerId: listing.sellerId,
            sellerType: listing.sellerType,
            bidHistory: listing.bidHistory ?? [],
            summary: listing.summary ?? "",
            specification: {
                modelNumber: listing.specification.modelNumber ?? "",
                condition: listing.specification.condition ?? "",
                material: listing.specification.material ?? "",
                movement: listing.specification.movement ?? "",
                lot: listing.specification.lot ?? "",
                case: listing.specification.case ?? "",
                box: listing.specification.box ?? "",
                papers: listing.specification.papers ?? "",
                waterproof: listing.specification.waterproof ?? "",
            },
            conditionDetails: {
                description: listing.conditionDetails.description,
                images: listing.conditionDetails.images,
            },
            sellerInfo: {
                user: listing.sellerInfo.user,
                location: listing.sellerInfo.location,
                sellerType: listing.sellerInfo.sellerType,
            },
            comments: listing.comments,
            images: listing.images,
            showroomImage: listing.showroomImage,
            status: listing.listingStatus ?? listing.itemStatus ?? "",
            winningBid: listing.winningBid,
            minBid: listing.minBid ?? 0,
        };

        setPreviewWatchDetails(watchDetails);
        setShowPreviewWatchDetailsDialog(true);
    }, []);

    const updateField = useCallback(
        (listing: WatchListing, field: string, newValue: string) => {
            switch (field) {
                case "productId": {
                    listing.productId = newValue;
                    break;
                }
                case "brand": {
                    listing.brand = newValue;
                    break;
                }
                case "model": {
                    listing.model = newValue;
                    break;
                }
                case "year": {
                    listing.year = newValue;
                    break;
                }
                case "summary": {
                    listing.summary = newValue;
                    break;
                }
                case "modelNumber": {
                    listing.specification.modelNumber = newValue;
                    break;
                }
                case "condition": {
                    listing.specification.condition = newValue;
                    break;
                }
                case "lot": {
                    listing.specification.lot = newValue;
                    break;
                }
                case "box": {
                    listing.specification.box = newValue;
                    break;
                }
                case "papers": {
                    listing.specification.papers = newValue;
                    break;
                }
                case "condition description": {
                    listing.conditionDetails.description = newValue;
                    break;
                }
                default: {
                    return;
                }
            }

            setListingToEdit({ ...listing });
        },
        [],
    );

    const handleEditListing = useCallback((specific: boolean) => {
        const cloneListing = JSON.parse(
            JSON.stringify(watchDetails),
        ) as WatchListing;
        cloneListing.auctionStartDate = watchDetails.auctionStartDate
            ? new Date(watchDetails.auctionStartDate)
            : undefined;
        cloneListing.auctionEndDate = watchDetails.auctionEndDate
            ? new Date(watchDetails.auctionEndDate)
            : undefined;
        setListingToEdit(cloneListing);
        setSpecificEdit(specific);
    }, [watchDetails]);

    const handleEditListingImages = useCallback(() => {
        setShowEditImagesDialog(true);
    }, []);

    const handleStartDrafting = useCallback(() => {
        startDrafting(watchDetails);
    }, [startDrafting, watchDetails]);

    const handleRejectProposal = useCallback(() => {
        rejectProposal(watchDetails);
    }, [rejectProposal, watchDetails]);

    const handleRejectDraft = useCallback(() => {
        rejectDraft(watchDetails);
    }, [rejectDraft, watchDetails]);

    const handleRequestApproval = useCallback(() => {
        if (canRequestApproval) {
            requestApproval(watchDetails);
        }
    }, [canRequestApproval, requestApproval, watchDetails]);

    const handleComingSoon = useCallback(() => {
        updateToComingSoon(watchDetails);
    }, [updateToComingSoon, watchDetails]);

    const validateListing = useCallback(
        (listing: WatchListing) => {
            if (!listing.auctionStartDate || !listing.auctionEndDate) {
                showSnackbar(
                    "Please ensure both an auction start date and auction end date are specified",
                    { alertSeverity: "error" },
                );
                return false;
            }

            if (listing.auctionEndDate <= listing.auctionStartDate) {
                showSnackbar(
                    "Please ensure auction start date is before the auction end date",
                    { alertSeverity: "error" },
                );
                return false;
            }

            return true;
        },
        [showSnackbar],
    );

    const handleSaveEditing = useCallback(async () => {
        if (listingToEdit) {
            if (!validateListing(listingToEdit)) {
                return;
            }

            await updateListing(watchDetails, listingToEdit);
            setListingToEdit(undefined);
        }
    }, [listingToEdit, updateListing, validateListing, watchDetails]);

    const handleCancelEditing = useCallback(() => {
        setListingToEdit(undefined);
    }, []);

    const onInit = useCallback((detail: InitDetail) => {
        lightGalleryRef.current = detail.instance;
    }, []);

    const onConditionsInit = useCallback((detail: InitDetail) => {
        lightGalleryConditionsRef.current = detail.instance;
    }, []);

    const handleGalleryShow = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            if (lightGalleryRef.current) {
                lightGalleryRef.current.openGallery();
            }
        },
        [],
    );

    const handleConditionShow = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            if (lightGalleryConditionsRef.current) {
                lightGalleryConditionsRef.current.openGallery();
            }
        },
        [],
    );

    const handleViewDetails = useCallback(() => {
        if (watchDetails?.listingId) {
            navigate(`/details?id=${watchDetails.listingId}`);
        }
    }, [navigate, watchDetails?.listingId]);

    const getActionButtons = useCallback((actions: ActionItem[]) => {
        return (
            <>
                {actions.map((action) => (
                    <Button
                        key={action.id}
                        className={styles.actionButton}
                        variant="contained"
                        disableElevation
                        onClick={action.action}
                    >
                        <Typography className={styles.actionText}>
                            {action.name}
                        </Typography>
                    </Button>
                ))}
            </>
        );
    }, []);

    const handleChangeBrand = useCallback(
        (value: any, listingToEdit: WatchListing) => {
            if (!value) {
                updateField(listingToEdit, "brand", "");
                updateField(listingToEdit, "model", "");
                updateField(listingToEdit, "modelNumber", "");
                setModels([]);
                setVariants([]);
            } else {
                updateField(listingToEdit, "brand", value);
                updateField(listingToEdit, "model", "");
                const modelsInfo = [
                    ...new Set(allProducts[value].map((item) => item.model)),
                ];
                setModels(modelsInfo);
            }
        },
        [allProducts, updateField],
    );

    const handleChangeModel = useCallback(
        (value: any, listingToEdit: WatchListing) => {
            if (!value) {
                updateField(listingToEdit, "modelNumber", "");
                setVariants([]);
            }
            updateField(listingToEdit, "model", value);
            if (listingToEdit && listingToEdit.brand) {
                const productId = allProducts[listingToEdit.brand].find(
                    (item) => item.model === value,
                );
                if (productId)
                    updateField(listingToEdit, "productId", productId?.id);
                const allVariants = allProducts[listingToEdit.brand]
                    .filter((item) => item.model === value)
                    .map((item) => item.variant);
                setVariants(allVariants);
            }
        },
        [allProducts, updateField],
    );

    const handleChangeVariant = useCallback(
        (value: any, listingToEdit: WatchListing) => {
            updateField(listingToEdit, "modelNumber", value);
            if (listingToEdit && listingToEdit.brand) {
                let product = allProducts[listingToEdit.brand].find(
                    (item) =>
                        item.model === listingToEdit.model &&
                        item.variant === value,
                );

                if (!product) {
                    product = allProducts[listingToEdit.brand].find(
                        (item) => item.model === listingToEdit.model,
                    );
                }
                if (product)
                    updateField(listingToEdit, "productId", product?.id);
            }
        },
        [allProducts, updateField],
    );

    useEffect(() => {
        if (listingToEdit && listingToEdit.brand) {
            const modelsInfo = [
                ...new Set(
                    allProducts[listingToEdit.brand].map(
                        (item) => item.model,
                    ),
                ),
            ];
            setModels(modelsInfo);
    
            const allVariants = allProducts[listingToEdit.brand]
                .filter((item) => item.model === listingToEdit.model)
                .map((item) => item.variant);
            setVariants(allVariants);
        }
    }, [listingToEdit, allProducts]);
    return (
        <Stack direction='column' className={styles.root}>
            <Stack
                direction="column"
                className={styles.picturesContainer}
            >
                {allImages.length > 0 ? (
                    <>
                        <div className={styles.mainImageContainer}>
                            <a
                                className={styles.imageOverlayLink}
                                href="/"
                                onClick={handleGalleryShow}
                            >
                                <img
                                    alt="img1"
                                    src={allImages[0].thumbnailUrl}
                                    className={styles.mainImage}
                                />
                                {/* <ZoomOutMapIcon className={styles.overlayIcon} /> */}
                            </a>
                        </div>
                        <div className={styles.otherImagesContainer}>
                            <LightGallery
                                licenseKey={LightGalleryLicense}
                                speed={500}
                                onInit={onInit}
                                plugins={[lgThumbnail, lgZoom]}
                                getCaptionFromTitleOrAlt={false}
                                showCloseIcon={true}
                            >
                                {allImages.map((image, index) => (
                                    <a
                                        key={`${watchDetails.itemId}_${image.mediaUrl}`}
                                        href={image.mediaUrl}
                                    >
                                        <img
                                            alt="img"
                                            src={image.thumbnailUrl}
                                            className={
                                                index === 0
                                                    ? styles.thumbnailImageFirst
                                                    : styles.thumbnailImage
                                            }
                                        />
                                    </a>
                                ))}
                            </LightGallery>
                            {allImages.length > 3 && (
                                <a href="/" onClick={handleGalleryShow}>
                                    <div className={styles.moreContainer}>
                                        <Typography className={styles.moreText}>
                                            +{allImages.length - 3}
                                        </Typography>
                                    </div>
                                </a>
                            )}
                        </div>
                    </>
                ) : (
                    <Stack
                        direction="column"
                        className={styles.watchNoImageContainer}
                    >
                        <NoPhotographyOutlinedIcon />
                        <Typography className={styles.watchNoImageText}>
                            Image Unavailable
                        </Typography>
                    </Stack>
                )}
            </Stack>

            <Stack direction='column' className={styles.statusContainer}>
                <Typography className={styles.statusText} noWrap>STATUS</Typography>
                <Typography className={styles.statusValueText} noWrap>{getStatus(watchDetails)}</Typography>
            </Stack>

            <Stack
                direction="column"
                className={styles.detailsContainer}
            >
                {listingToEdit && !specificEdit ? (
                    <DropDown
                        id="watchBrandInput"
                        label=""
                        placeholder="e.g. Rolex"
                        disabled={!user}
                        data={brands}
                        onChange={(value) =>
                            handleChangeBrand(value, listingToEdit)
                        }
                        value={listingToEdit.brand}
                        sx={{ marginTop: 2 }}
                    />
                ) : (
                    <Typography className={styles.brandText}>
                        {watchDetails.brand}
                    </Typography>
                )}
                {listingToEdit && !specificEdit ? (
                    <DropDown
                        id="watchModelInput"
                        label=""
                        disabled={!user}
                        placeholder="e.g. Daydate"
                        data={models}
                        onChange={(value) =>
                            handleChangeModel(value, listingToEdit)
                        }
                        value={listingToEdit.model}
                        sx={{ marginTop: 2 }}
                    />
                ) : (
                    <Typography className={styles.modelText}>
                        {watchDetails.model}
                    </Typography>
                )}
                {listingToEdit && !specificEdit ? (
                    <TextField
                        sx={{ marginTop: 2 }}
                        className={styles.editable}
                        placeholder="Year"
                        value={listingToEdit.year ?? ""}
                        onChange={(e) =>
                            updateField(listingToEdit, "year", e.target.value)
                        }
                    />
                ) : (
                    <Typography
                        className={`${styles.yearText} ${
                            watchDetails.year ? "" : styles.inputRequired
                        }`}
                    >
                        {watchDetails.year ? watchDetails.year : inputRequired}
                    </Typography>
                )}
                {listingToEdit && !specificEdit ? (
                    <TextField
                        className={styles.editableMultiline}
                        placeholder="Summary"
                        value={
                            listingToEdit.summary
                                ? listingToEdit.summary.replaceAll(
                                      "<br />",
                                      "\n",
                                  )
                                : ""
                        }
                        onChange={(e) =>
                            updateField(
                                listingToEdit,
                                "summary",
                                e.target.value.replaceAll("\n", "<br />"),
                            )
                        }
                        multiline
                        rows={4}
                    />
                ) : watchDetails.summary ? (
                    watchDetails.summary.split("||").map((line, index) =>
                        line === "" ? (
                            <div
                                key={`${watchDetails.itemId}_summary_${index}`}
                                className={styles.summarySpacer}
                            />
                        ) : (
                            <Typography
                                key={`${watchDetails.itemId}_summary_${index}`}
                                className={styles.summaryText}
                            >
                                {Parser(line)}
                            </Typography>
                        ),
                    )
                ) : (
                    <Typography
                        className={`${styles.summaryText} ${styles.inputRequired}`}
                    >
                        {inputRequired}
                    </Typography>
                )}
                <Stack direction="row" className={styles.specsContainer}>
                    <Stack direction="column" className={listingToEdit && !specificEdit ? styles.fullRowEdit : undefined}>
                        <Typography className={styles.specTitle} noWrap>
                            Model Number:
                        </Typography>
                        {listingToEdit && !specificEdit ? (
                            <DropDown
                                variant                                
                                id="watchVariantInput"
                                label=""
                                disabled={!user}
                                placeholder="e.g. 116500LN"
                                data={variants}
                                onChange={(value) =>
                                    handleChangeVariant(value, listingToEdit)
                                }
                                value={
                                    listingToEdit.specification.modelNumber ??
                                    ""
                                }
                            />
                        ) : (
                            <Typography
                                className={`${styles.specText} ${
                                    watchDetails.specification.modelNumber
                                        ? ""
                                        : styles.inputRequired
                                }`}
                            >
                                {watchDetails.specification.modelNumber
                                    ? watchDetails.specification.modelNumber
                                    : inputRequired}
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Stack direction="row">
                            <Typography className={styles.specTitle} noWrap>
                                Condition:
                            </Typography>
                            <ConditionTooltip isLarge={false} />
                        </Stack>
                        {listingToEdit && !specificEdit ? (
                            <Select
                                className={styles.editableDropdown}
                                variant="outlined"
                                value={
                                    listingToEdit.specification.condition ?? ""
                                }
                                onChange={(e) =>
                                    updateField(
                                        listingToEdit,
                                        "condition",
                                        e.target.value,
                                    )
                                }
                            >
                                <MenuItem value={""}></MenuItem>
                                <MenuItem value={"A"}>A</MenuItem>
                                <MenuItem value={"B"}>B</MenuItem>
                                <MenuItem value={"C"}>C</MenuItem>
                                <MenuItem value={"D"}>D</MenuItem>
                                <MenuItem value={"E"}>E</MenuItem>
                                <MenuItem value={"F"}>F</MenuItem>
                            </Select>
                        ) : (
                            <Typography
                                className={`${styles.specText} ${
                                    watchDetails.specification.condition
                                        ? ""
                                        : styles.inputRequired
                                }`}
                            >
                                {watchDetails.specification.condition
                                    ? watchDetails.specification.condition
                                    : inputRequired}
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Typography className={styles.specTitle} noWrap>
                            Material:
                        </Typography>
                        <Typography className={styles.specText}>
                            {watchDetails.specification.material}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Typography className={styles.specTitle} noWrap>
                            Movement:
                        </Typography>
                        <Typography className={styles.specText}>
                            {watchDetails.specification.movement}
                        </Typography>
                    </Stack>
                    {watchDetails.listingId ? (
                        <Stack
                            direction="column"
                            className={styles.specItemContainer}
                        >
                            <Typography className={styles.specTitle} noWrap>
                                Lot:
                            </Typography>
                            {listingToEdit && !specificEdit ? (
                                <TextField
                                    className={styles.editable}
                                    placeholder="Lot"
                                    value={
                                        listingToEdit.specification.lot ?? ""
                                    }
                                    onChange={(e) =>
                                        updateField(
                                            listingToEdit,
                                            "lot",
                                            e.target.value,
                                        )
                                    }
                                />
                            ) : (
                                <Typography
                                    className={`${styles.specText} ${
                                        watchDetails.specification.lot
                                            ? ""
                                            : styles.inputRequired
                                    }`}
                                >
                                    {watchDetails.specification.lot
                                        ? watchDetails.specification.lot
                                        : inputRequired}
                                </Typography>
                            )}
                        </Stack>
                    ) : null}
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Typography className={styles.specTitle} noWrap>
                            Case:
                        </Typography>
                        <Typography className={styles.specText}>
                            {watchDetails.specification.case}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Typography className={styles.specTitle} noWrap>
                            Box:
                        </Typography>
                        {listingToEdit && !specificEdit ? (
                            <TextField
                                className={styles.editable}
                                placeholder="Box"
                                value={listingToEdit.specification.box ?? ""}
                                onChange={(e) =>
                                    updateField(
                                        listingToEdit,
                                        "box",
                                        e.target.value,
                                    )
                                }
                            />
                        ) : (
                            <Typography
                                className={`${styles.specText} ${
                                    watchDetails.specification.box
                                        ? ""
                                        : styles.inputRequired
                                }`}
                            >
                                {watchDetails.specification.box
                                    ? watchDetails.specification.box
                                    : inputRequired}
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Typography className={styles.specTitle} noWrap>
                            Papers:
                        </Typography>
                        {listingToEdit && !specificEdit ? (
                            <TextField
                                className={styles.editable}
                                placeholder="Papers"
                                value={listingToEdit.specification.papers ?? ""}
                                onChange={(e) =>
                                    updateField(
                                        listingToEdit,
                                        "papers",
                                        e.target.value,
                                    )
                                }
                            />
                        ) : (
                            <Typography
                                className={`${styles.specText} ${
                                    watchDetails.specification.papers
                                        ? ""
                                        : styles.inputRequired
                                }`}
                            >
                                {watchDetails.specification.papers
                                    ? watchDetails.specification.papers
                                    : inputRequired}
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        direction="column"
                        className={styles.specItemContainer}
                    >
                        <Typography className={styles.specTitle} noWrap>
                            Waterproof:
                        </Typography>
                        <Typography className={styles.specText}>
                            {watchDetails.specification.waterproof}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row">
                    <div
                        className={styles.conditionButton}
                        onClick={(e) => setShowCondition((prev) => !prev)}
                    >
                        <Typography
                            className={styles.conditionButtonText}
                            noWrap
                        >
                            Condition
                        </Typography>
                        {showCondition ? (
                            <ArrowDropDownIcon
                                className={styles.conditionButtonImage}
                            />
                        ) : (
                            <ArrowDropUpIcon
                                className={styles.conditionButtonImage}
                            />
                        )}
                    </div>
                </Stack>
                {showCondition ? (
                    watchDetails.conditionDetails.images.length > 0 ||
                    watchDetails.conditionDetails.description ? (
                        <Stack
                            direction="column"
                            className={styles.conditionContentContainer}
                        >
                            {watchDetails.conditionDetails.images.length > 0 ? (
                                <div className={styles.otherImagesContainer}>
                                    <LightGallery
                                        licenseKey={LightGalleryLicense}
                                        speed={500}
                                        onInit={onConditionsInit}
                                        plugins={[lgThumbnail, lgZoom]}
                                        getCaptionFromTitleOrAlt={false}
                                        showCloseIcon={true}
                                    >
                                        {watchDetails.conditionDetails.images.map(
                                            (image, index) => (
                                                <a
                                                    key={`${watchDetails.itemId}_${image.id}`}
                                                    href={image.mediaUrl}
                                                >
                                                    <img
                                                        alt="img"
                                                        src={image.thumbnailUrl}
                                                        className={
                                                            index === 0
                                                                ? styles.thumbnailImageFirst
                                                                : styles.thumbnailImage
                                                        }
                                                    />
                                                </a>
                                            ),
                                        )}
                                    </LightGallery>
                                    {watchDetails.conditionDetails.images
                                        .length > 3 && (
                                        <a
                                            href="/"
                                            onClick={handleConditionShow}
                                        >
                                            <div
                                                className={styles.moreContainer}
                                            >
                                                <Typography
                                                    className={styles.moreText}
                                                >
                                                    +
                                                    {watchDetails
                                                        .conditionDetails.images
                                                        .length - 3}
                                                </Typography>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            ) : null}

                            {listingToEdit && !specificEdit  ? (
                                <TextField
                                    className={styles.editable}
                                    placeholder="Condition Description"
                                    value={
                                        listingToEdit.conditionDetails
                                            .description ?? ""
                                    }
                                    onChange={(e) =>
                                        updateField(
                                            listingToEdit,
                                            "condition description",
                                            e.target.value,
                                        )
                                    }
                                />
                            ) : watchDetails.conditionDetails.description ? (
                                <Typography className={styles.conditionText}>
                                    {watchDetails.conditionDetails.description}
                                </Typography>
                            ) : null}
                        </Stack>
                    ) : listingToEdit && !specificEdit ? (
                        <TextField
                            className={styles.editable}
                            placeholder="Condition Description"
                            value={
                                listingToEdit.conditionDetails.description ?? ""
                            }
                            onChange={(e) =>
                                updateField(
                                    listingToEdit,
                                    "condition description",
                                    e.target.value,
                                )
                            }
                        />
                    ) : (
                        <Stack
                            direction="row"
                            className={styles.conditionContentContainer}
                        >
                            <Typography className={styles.conditionNoInfo}>
                                No additional condition information
                            </Typography>
                        </Stack>
                    )
                ) : null}
            </Stack>

            <div className={styles.divider}>
                <div className={styles.dividerItem} />
            </div>

            {!watchDetails.listingStatus && watchDetails.itemStatus === "Proposal" && (
                <>
                <Stack
                    direction="column"
                    className={styles.detailsRightContainer}
                >
                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography
                            className={styles.listingInfoText}
                            noWrap
                        >
                            EMAIL & PHONE
                        </Typography>
                        {watchDetails.sellerInfo.email &&
                        watchDetails.sellerInfo.email !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`mailto:${watchDetails.sellerInfo.email}`}
                                >
                                    {watchDetails.sellerInfo.email}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.email}
                            </Typography>
                        )}
                        {watchDetails.sellerInfo.phone &&
                        watchDetails.sellerInfo.phone !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`tel:${watchDetails.sellerInfo.phone}`}
                                >
                                    {watchDetails.sellerInfo.phone}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.phone}
                            </Typography>
                        )}
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography
                            className={styles.listingInfoText}
                            noWrap
                        >
                            DATE SENT
                        </Typography>
                        <Typography
                            className={styles.listingInfoValue}
                            noWrap
                        >
                            {getDisplayDate(watchDetails.createdOn)}
                        </Typography>
                    </Stack>
                </Stack>

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                <Stack
                    direction="row"
                    className={styles.actionsContainer}
                >
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleStartDrafting}
                    >
                        <Typography className={styles.actionButtonText}>
                            Accept & Start Drafting
                        </Typography>
                    </Button>
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleRejectProposal}
                    >
                        <Typography className={styles.actionButtonText}>
                            Reject
                        </Typography>
                    </Button>
                    {getActionButtons(actions)}
                </Stack>
                </>
            )}

            {watchDetails.listingId &&
                watchDetails.listingStatus === "Draft" && (
                    <>
                    <div className={listingToEdit ? styles.detailsRightContainerEditing : styles.detailsRightContainer}>
                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                RESERVE
                            </Typography>
                            {listingToEdit ? (
                                <TextField
                                    className={styles.listingInfoValue}
                                    fullWidth
                                    id="reserveInput"
                                    InputProps={{
                                        inputComponent:
                                            NumericFormatCustom as any,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Stack direction="row">
                                                    {listingToEdit.reservePrice !==
                                                    undefined ? (
                                                        <IconButton
                                                            className={
                                                                styles.clearButton
                                                            }
                                                            onClick={() =>
                                                                (listingToEdit.reservePrice =
                                                                    undefined)
                                                            }
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Stack>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: "11px",
                                            fontWeight: "400",
                                        },
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                display: "none",
                                            },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    placeholder={"No reserve"}
                                    value={
                                        listingToEdit.reservePrice
                                            ? listingToEdit.reservePrice
                                            : undefined
                                    }
                                    onChange={(e) => {
                                        const newValue = Number(e.target.value);
                                        listingToEdit.reservePrice =
                                            Number.isNaN(newValue) ||
                                            newValue <= 0
                                                ? undefined
                                                : newValue;
                                    }}
                                    // error={!!bidError}
                                    // helperText={bidError}
                                    variant="standard"
                                    disabled={!user?.email_verified}
                                />
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.reservePrice
                                        ? `£${watchDetails.reservePrice.toLocaleString()}`
                                        : "No reserve"}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                EMAIL & PHONE
                            </Typography>
                            {watchDetails.sellerInfo.email &&
                            watchDetails.sellerInfo.email !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`mailto:${watchDetails.sellerInfo.email}`}
                                    >
                                        {watchDetails.sellerInfo.email}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.email}
                                </Typography>
                            )}
                            {watchDetails.sellerInfo.phone &&
                            watchDetails.sellerInfo.phone !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`tel:${watchDetails.sellerInfo.phone}`}
                                    >
                                        {watchDetails.sellerInfo.phone}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.phone}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE TO BE LISTED
                            </Typography>
                            {listingToEdit ? (
                                <DatePicker
                                    className={styles.dateInput}
                                    value={listingToEdit.auctionStartDate}
                                    onChange={(newValue) =>
                                        newValue
                                            ? (listingToEdit.auctionStartDate =
                                                  new Date(newValue))
                                            : undefined
                                    }
                                />
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {getDisplayDate(
                                        watchDetails.auctionStartDate,
                                        watchDetails.auctionStartDateDescription,
                                    )}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                AUCTION ENDS
                            </Typography>
                            {listingToEdit ? (
                                <DatePicker
                                    className={styles.dateInput}
                                    value={listingToEdit.auctionEndDate}
                                    onChange={(newValue) =>
                                        newValue
                                            ? (listingToEdit.auctionEndDate =
                                                  new Date(newValue))
                                            : undefined
                                    }
                                />
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {getDisplayDate(
                                        watchDetails.auctionEndDate,
                                        watchDetails.auctionEndDateDescription,
                                    )}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE SENT
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(watchDetails.createdOn)}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                PAYMENT TYPES ACCEPTED
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                Stripe
                            </Typography>
                        </Stack>
                    </div>

                    <div className={styles.divider}>
                        <div className={styles.dividerItem} />
                    </div>

                    <Stack
                        direction="row"
                        className={styles.actionsContainer}
                    >
                        {listingToEdit ? (
                            <>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={() =>
                                        displayPreview(listingToEdit)
                                    }
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Preview
                                    </Typography>
                                </Button>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleSaveEditing}
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Save Changes
                                    </Typography>
                                </Button>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleCancelEditing}
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Cancel Changes
                                    </Typography>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={() =>
                                        displayPreview(watchDetails)
                                    }
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Preview
                                    </Typography>
                                </Button>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={() => handleEditListing(false)}
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Edit Listing
                                    </Typography>
                                </Button>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleEditListingImages}
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Edit Images
                                    </Typography>
                                </Button>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleRequestApproval}
                                    disabled={!canRequestApproval}
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Request Approval
                                    </Typography>
                                </Button>
                                <Button
                                    className={styles.actionButtonStrong}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleRejectDraft}
                                >
                                    <Typography
                                        className={styles.actionButtonText}
                                    >
                                        Reject
                                    </Typography>
                                </Button>
                            </>
                        )}
                        {getActionButtons(actions)}
                    </Stack>
                    </>
                )}

            {watchDetails.listingId &&
                watchDetails.listingStatus === "ApprovalRequested" && (
                    <>
                    <Stack
                        direction="row"
                        className={styles.detailsRightContainer}
                    >
                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                RESERVE
                            </Typography>
                            
                            {listingToEdit ? (
                                <TextField
                                    className={styles.listingInfoValue}
                                    fullWidth
                                    id="reserveInput"
                                    InputProps={{
                                        inputComponent:
                                            NumericFormatCustom as any,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Stack direction="row">
                                                    {listingToEdit.reservePrice !==
                                                    undefined ? (
                                                        <IconButton
                                                            className={
                                                                styles.clearButton
                                                            }
                                                            onClick={() =>
                                                                (listingToEdit.reservePrice =
                                                                    undefined)
                                                            }
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Stack>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: "11px",
                                            fontWeight: "400",
                                        },
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                display: "none",
                                            },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    placeholder={"No reserve"}
                                    value={
                                        listingToEdit.reservePrice
                                            ? listingToEdit.reservePrice
                                            : undefined
                                    }
                                    onChange={(e) => {
                                        const newValue = Number(e.target.value);
                                        listingToEdit.reservePrice =
                                            Number.isNaN(newValue) ||
                                            newValue <= 0
                                                ? undefined
                                                : newValue;
                                    }}
                                    // error={!!bidError}
                                    // helperText={bidError}
                                    variant="standard"
                                    disabled={!user?.email_verified}
                                />
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.reservePrice
                                        ? `£${watchDetails.reservePrice.toLocaleString()}`
                                        : "No reserve"}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                EMAIL & PHONE
                            </Typography>
                            {watchDetails.sellerInfo.email &&
                            watchDetails.sellerInfo.email !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`mailto:${watchDetails.sellerInfo.email}`}
                                    >
                                        {watchDetails.sellerInfo.email}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.email}
                                </Typography>
                            )}
                            {watchDetails.sellerInfo.phone &&
                            watchDetails.sellerInfo.phone !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`tel:${watchDetails.sellerInfo.phone}`}
                                    >
                                        {watchDetails.sellerInfo.phone}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.phone}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE TO BE LISTED
                            </Typography>
                            {listingToEdit ? (
                                <DatePicker
                                    className={styles.dateInput}
                                    value={listingToEdit.auctionStartDate}
                                    onChange={(newValue) =>
                                        newValue
                                            ? (listingToEdit.auctionStartDate =
                                                  new Date(newValue))
                                            : undefined
                                    }
                                />
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {getDisplayDate(
                                        watchDetails.auctionStartDate,
                                        watchDetails.auctionStartDateDescription,
                                    )}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                AUCTION ENDS
                            </Typography>
                            {listingToEdit ? (
                                <DatePicker
                                    className={styles.dateInput}
                                    value={listingToEdit.auctionEndDate}
                                    onChange={(newValue) =>
                                        newValue
                                            ? (listingToEdit.auctionEndDate =
                                                  new Date(newValue))
                                            : undefined
                                    }
                                />
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {getDisplayDate(
                                        watchDetails.auctionEndDate,
                                        watchDetails.auctionEndDateDescription,
                                    )}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE SENT
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(watchDetails.createdOn)}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                PAYMENT TYPES ACCEPTED
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                Stripe
                            </Typography>
                        </Stack>
                    </Stack>
                    
                    <div className={styles.divider}>
                        <div className={styles.dividerItem} />
                    </div>

                    <Stack
                        direction="row"
                        className={styles.actionsContainer}
                    >
                        {
                            listingToEdit && specificEdit ?
                            <Button
                                className={styles.actionButtonStrong}
                                variant="contained"
                                disableElevation
                                onClick={handleSaveEditing}
                            >
                                <Typography
                                    className={styles.actionButtonText}
                                >
                                    Save Changes
                                </Typography>
                            </Button>
                            :
                            <Button
                                className={styles.actionButtonStrong}
                                variant="contained"
                                disableElevation
                                onClick={() => handleEditListing(true)}
                            >
                                <Typography
                                    className={styles.actionButtonText}
                                >
                                    Edit Listing
                                </Typography>
                            </Button>
                        }
                        
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={() => displayPreview(watchDetails)}
                        >
                            <Typography className={styles.actionButtonText}>
                                Preview
                            </Typography>
                        </Button>
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={handleRejectDraft}
                        >
                            <Typography className={styles.actionButtonText}>
                                Reject
                            </Typography>
                        </Button>
                        {getActionButtons(actions)}
                    </Stack>
                    </>
                )}

            {watchDetails.listingId &&
                watchDetails.listingStatus === "Accepted" && (
                    <>
                    <Stack
                        direction="row"
                        className={styles.detailsRightContainer}
                    >
                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                RESERVE
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.reservePrice
                                    ? `£${watchDetails.reservePrice.toLocaleString()}`
                                    : "No reserve"}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                EMAIL & PHONE
                            </Typography>
                            {watchDetails.sellerInfo.email &&
                            watchDetails.sellerInfo.email !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`mailto:${watchDetails.sellerInfo.email}`}
                                    >
                                        {watchDetails.sellerInfo.email}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.email}
                                </Typography>
                            )}
                            {watchDetails.sellerInfo.phone &&
                            watchDetails.sellerInfo.phone !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`tel:${watchDetails.sellerInfo.phone}`}
                                    >
                                        {watchDetails.sellerInfo.phone}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.phone}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE TO BE LISTED
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(
                                    watchDetails.auctionStartDate,
                                    watchDetails.auctionStartDateDescription,
                                )}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                AUCTION ENDS
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(
                                    watchDetails.auctionEndDate,
                                    watchDetails.auctionEndDateDescription,
                                )}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE SENT
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(watchDetails.createdOn)}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                PAYMENT TYPES ACCEPTED
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                Stripe
                            </Typography>
                        </Stack>
                    </Stack>

                    <div className={styles.divider}>
                        <div className={styles.dividerItem} />
                    </div>

                    <Stack
                        direction="row"
                        className={styles.actionsContainer}
                    >
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={() => displayPreview(watchDetails)}
                        >
                            <Typography className={styles.actionButtonText}>
                                Preview
                            </Typography>
                        </Button>
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={handleComingSoon}
                        >
                            <Typography className={styles.actionButtonText}>
                                Update to Coming Soon
                            </Typography>
                        </Button>
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={handleRejectDraft}
                        >
                            <Typography className={styles.actionButtonText}>
                                Reject
                            </Typography>
                        </Button>
                        {getActionButtons(actions)}
                    </Stack>
                    </>
                )}

            {watchDetails.listingId &&
                watchDetails.listingStatus === "ComingSoon" && (
                    <>
                    <Stack
                        direction="row"
                        className={styles.detailsRightContainer}
                    >
                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                RESERVE
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.reservePrice
                                    ? `£${watchDetails.reservePrice.toLocaleString()}`
                                    : "No reserve"}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                EMAIL & PHONE
                            </Typography>
                            {watchDetails.sellerInfo.email &&
                            watchDetails.sellerInfo.email !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`mailto:${watchDetails.sellerInfo.email}`}
                                    >
                                        {watchDetails.sellerInfo.email}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.email}
                                </Typography>
                            )}
                            {watchDetails.sellerInfo.phone &&
                            watchDetails.sellerInfo.phone !== "unknown" ? (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    <a
                                        href={`tel:${watchDetails.sellerInfo.phone}`}
                                    >
                                        {watchDetails.sellerInfo.phone}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography
                                    className={styles.listingInfoValue}
                                    noWrap
                                >
                                    {watchDetails.sellerInfo.phone}
                                </Typography>
                            )}
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE TO BE LISTED
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(
                                    watchDetails.auctionStartDate,
                                    watchDetails.auctionStartDateDescription,
                                )}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                AUCTION ENDS
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(
                                    watchDetails.auctionEndDate,
                                    watchDetails.auctionEndDateDescription,
                                )}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                DATE SENT
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {getDisplayDate(watchDetails.createdOn)}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="column"
                            className={styles.listingInfoContainer}
                        >
                            <Typography
                                className={styles.listingInfoText}
                                noWrap
                            >
                                PAYMENT TYPES ACCEPTED
                            </Typography>
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                Stripe
                            </Typography>
                        </Stack>
                    </Stack>

                    <div className={styles.divider}>
                        <div className={styles.dividerItem} />
                    </div>

                    <Stack
                        direction="row"
                        className={styles.actionsContainer}
                    >
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={handleViewDetails}
                        >
                            <Typography className={styles.actionButtonText}>
                                View Details
                            </Typography>
                        </Button>
                        <Button
                            className={styles.actionButtonStrong}
                            variant="contained"
                            disableElevation
                            onClick={handleWithdrawListing}
                        >
                            <Typography className={styles.actionButtonText}>
                                Withdraw
                            </Typography>
                        </Button>
                        {getActionButtons(actions)}
                    </Stack>
                    </>
                )}

            {watchDetails.listingStatus === "Live" && (
                <>
                <Stack
                    direction="row"
                    className={styles.detailsRightContainer}
                >
                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            CURRENT BID
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.currentBid
                                ? `£${watchDetails.currentBid.toLocaleString()}`
                                : "No bids"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            RESERVE
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.reservePrice
                                ? `£${watchDetails.reservePrice.toLocaleString()}`
                                : "No reserve"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            EMAIL & PHONE
                        </Typography>
                        {watchDetails.sellerInfo.email &&
                        watchDetails.sellerInfo.email !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`mailto:${watchDetails.sellerInfo.email}`}
                                >
                                    {watchDetails.sellerInfo.email}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.email}
                            </Typography>
                        )}
                        {watchDetails.sellerInfo.phone &&
                        watchDetails.sellerInfo.phone !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`tel:${watchDetails.sellerInfo.phone}`}
                                >
                                    {watchDetails.sellerInfo.phone}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.phone}
                            </Typography>
                        )}
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE LISTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionStartDate,
                                watchDetails.auctionStartDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            AUCTION ENDS
                        </Typography>
                        <Stack direction="row">
                            <EndTimer
                                endDate={watchDetails.auctionEndDate}
                                endDateDescription={
                                    watchDetails.auctionEndDateDescription
                                }
                                isLarge={false}
                                onCountdownFinished={refreshListings}
                            />
                        </Stack>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE SENT
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(watchDetails.createdOn)}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            PAYMENT TYPES ACCEPTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            Stripe
                        </Typography>
                    </Stack>

                    {/* <Stack direction='row' className={styles.contractContainer}>
                        <AttachFileIcon className={styles.contractIcon} />
                        <Typography className={styles.contractText} noWrap><a href='/'>contract.pdf</a></Typography>
                    </Stack> */}
                </Stack>

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                <Stack
                    direction="row"
                    className={styles.actionsContainer}
                >
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleViewDetails}
                    >
                        <Typography className={styles.actionButtonText}>
                            View Details
                        </Typography>
                    </Button>
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleWithdrawListing}
                    >
                        <Typography className={styles.actionButtonText}>
                            Withdraw
                        </Typography>
                    </Button>
                    {getActionButtons(actions)}
                </Stack>
                </>
            )}

            {watchDetails.listingStatus === "EndedNotSold" && (
                <>
                <Stack
                    direction="row"
                    className={styles.detailsRightContainer}
                >
                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            HIGHEST BID
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.currentBid
                                ? `£${watchDetails.currentBid.toLocaleString()}`
                                : "No bids"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            RESERVE
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.reservePrice
                                ? `£${watchDetails.reservePrice.toLocaleString()}`
                                : "No reserve"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            EMAIL & PHONE
                        </Typography>
                        {watchDetails.sellerInfo.email &&
                        watchDetails.sellerInfo.email !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`mailto:${watchDetails.sellerInfo.email}`}
                                >
                                    {watchDetails.sellerInfo.email}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.email}
                            </Typography>
                        )}
                        {watchDetails.sellerInfo.phone &&
                        watchDetails.sellerInfo.phone !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`tel:${watchDetails.sellerInfo.phone}`}
                                >
                                    {watchDetails.sellerInfo.phone}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.phone}
                            </Typography>
                        )}
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE LISTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionStartDate,
                                watchDetails.auctionStartDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            AUCTION ENDED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionEndDate,
                                watchDetails.auctionEndDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE SENT
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(watchDetails.createdOn)}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            PAYMENT TYPES ACCEPTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            Stripe
                        </Typography>
                    </Stack>
                </Stack>

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                <Stack
                    direction="row"
                    className={styles.actionsContainer}
                >
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleViewDetails}
                    >
                        <Typography className={styles.actionButtonText}>
                            View Details
                        </Typography>
                    </Button>
                    {getActionButtons(actions)}
                </Stack>
                </>                
            )}

            {watchDetails.listingStatus === "EndedSold" && (
                <>
                <Stack
                    direction="row"
                    className={styles.detailsRightContainer}
                >
                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            HIGHEST BID
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.currentBid
                                ? `£${watchDetails.currentBid.toLocaleString()}`
                                : "No bids"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            RESERVE
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.reservePrice
                                ? `£${watchDetails.reservePrice.toLocaleString()}`
                                : "No reserve"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            EMAIL & PHONE
                        </Typography>
                        {watchDetails.sellerInfo.email &&
                        watchDetails.sellerInfo.email !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`mailto:${watchDetails.sellerInfo.email}`}
                                >
                                    {watchDetails.sellerInfo.email}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.email}
                            </Typography>
                        )}
                        {watchDetails.sellerInfo.phone &&
                        watchDetails.sellerInfo.phone !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`tel:${watchDetails.sellerInfo.phone}`}
                                >
                                    {watchDetails.sellerInfo.phone}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.phone}
                            </Typography>
                        )}
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE LISTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionStartDate,
                                watchDetails.auctionStartDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            AUCTION ENDED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionEndDate,
                                watchDetails.auctionEndDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE SENT
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(watchDetails.createdOn)}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            PAYMENT TYPES ACCEPTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            Stripe
                        </Typography>
                    </Stack>

                    {/* <Stack direction='row' className={styles.contractContainer}>
                        <AttachFileIcon className={styles.contractIcon} />
                        <Typography className={styles.contractText} noWrap><a href='/'>contract.pdf</a></Typography>
                    </Stack> */}                    
                </Stack>

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                <Stack
                    direction="row"
                    className={styles.actionsContainer}
                >
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleViewDetails}
                    >
                        <Typography className={styles.actionButtonText}>
                            View Details
                        </Typography>
                    </Button>
                    {getActionButtons(actions)}
                </Stack>
                </>
            )}

            {["Rejected", "Withdrawn"].includes(
                watchDetails.listingStatus ?? "",
            ) && (
                <>
                <Stack
                    direction="row"
                    className={styles.detailsRightContainer}
                >
                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            HIGHEST BID
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.currentBid
                                ? `£${watchDetails.currentBid.toLocaleString()}`
                                : "No bids"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            RESERVE
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {watchDetails.reservePrice
                                ? `£${watchDetails.reservePrice.toLocaleString()}`
                                : "No reserve"}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            EMAIL & PHONE
                        </Typography>
                        {watchDetails.sellerInfo.email &&
                        watchDetails.sellerInfo.email !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`mailto:${watchDetails.sellerInfo.email}`}
                                >
                                    {watchDetails.sellerInfo.email}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.email}
                            </Typography>
                        )}
                        {watchDetails.sellerInfo.phone &&
                        watchDetails.sellerInfo.phone !== "unknown" ? (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                <a
                                    href={`tel:${watchDetails.sellerInfo.phone}`}
                                >
                                    {watchDetails.sellerInfo.phone}
                                </a>
                            </Typography>
                        ) : (
                            <Typography
                                className={styles.listingInfoValue}
                                noWrap
                            >
                                {watchDetails.sellerInfo.phone}
                            </Typography>
                        )}
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE LISTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionStartDate,
                                watchDetails.auctionStartDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            AUCTION ENDED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(
                                watchDetails.auctionEndDate,
                                watchDetails.auctionEndDateDescription,
                            )}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            DATE SENT
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            {getDisplayDate(watchDetails.createdOn)}
                        </Typography>
                    </Stack>

                    <Stack
                        direction="column"
                        className={styles.listingInfoContainer}
                    >
                        <Typography className={styles.listingInfoText} noWrap>
                            PAYMENT TYPES ACCEPTED
                        </Typography>
                        <Typography className={styles.listingInfoValue} noWrap>
                            Stripe
                        </Typography>
                    </Stack>
                </Stack>

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                <Stack
                    direction="row"
                    className={styles.actionsContainer}
                >
                    <Button
                        className={styles.actionButtonStrong}
                        variant="contained"
                        disableElevation
                        onClick={handleViewDetails}
                    >
                        <Typography className={styles.actionButtonText}>
                            View Details
                        </Typography>
                    </Button>
                    {getActionButtons(actions)}
                </Stack>
                </>
            )}

            <EditImagesDialog
                isOpen={showEditImagesDialog}
                onClose={onCloseEditImagesDialog}
                watchDetails={watchDetails}
                updateListingImages={updateListingImages}
            />
            <WatchDetailsDialog
                isOpen={!!previewWatchDetails && showPreviewWatchDetailsDialog}
                onClose={onClosePreviewWatchDetailsDialog}
                watchDetails={previewWatchDetails}
                isPreview={true}
            />
            <WithdrawListingDialog
                isOpen={showWithdrawListingDialog}
                onOk={onOkWithdrawListingDialog}
                onCancel={onCancelWithdrawListingDialog}
            />
        </Stack>
    );
};

export default EditableWatchSummaryMobile;
