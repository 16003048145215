import { useEffect, useRef, useState } from 'react';
import styles from './EndTimer.module.scss';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import DateDiff from 'date-diff';
import {format} from 'date-fns';
import { Stack, Typography } from '@mui/material';

interface RemainingTime {
    hours: number;
    minutes: number;
    seconds: number
}

const padTime = (timePart: number) => {
    const timePartStr = timePart.toString();
    return timePartStr.length === 1 ? `0${timePartStr}` : timePartStr;
}

interface EndTimerProps {
    endDate: Date|undefined;
    endDateDescription: string|undefined;
    isLarge: boolean;
    onCountdownFinished?: () => void;
    withoutTime?: boolean;
}

const EndTimer = ({endDate, endDateDescription, isLarge, onCountdownFinished, withoutTime}: EndTimerProps) => {
    const [showTimer, setShowTimer] = useState(false);
    const [remainingTime, setRemainingTime] = useState<RemainingTime>({
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    const [endDateString, setEndDateString] = useState('');
    const intervalIdRef = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }

        if (endDate) {
            const today = new Date();
            const diff = new DateDiff(endDate, today);
            const daysRemaining = Math.floor(diff.days());
            const monthsRemaining = Math.floor(diff.months());
            const yearsRemaining = Math.floor(monthsRemaining / 12);
            const weeksRemaining = Math.floor((daysRemaining % 365) / 7);

            if (yearsRemaining >= 1) {
                setShowTimer(false);
                const formatInfo = withoutTime ? 'dd/MM/yyyy' : 'hh:mm a dd/MM/yyyy';
                setEndDateString(format(endDate, formatInfo));
            }
            else if (daysRemaining > 7) {
                setEndDateString(`${weeksRemaining} week${weeksRemaining > 1 ? 's' : ''}`);
                setShowTimer(false);
            } else if (diff.days() > 1) {
                setEndDateString(`${daysRemaining} day${daysRemaining > 1 ? 's' : ''} remaining`);
                setShowTimer(false);
            }else {
                setShowTimer(true);
                const totalSecs = diff.seconds();
                const totalMins = Math.trunc(totalSecs / 60);
                const remainingSecs = Math.trunc(totalSecs - (totalMins * 60));
                const remainingHours = Math.trunc(totalMins / 60);
                const remainingMins = totalMins - (remainingHours * 60);
                
                setRemainingTime({
                    hours: remainingHours,
                    minutes: remainingMins,
                    seconds: remainingSecs
                });
                
                if (remainingHours > 0 || remainingMins > 0 || remainingSecs > 0) {
                    intervalIdRef.current = setInterval(() => {
                        setRemainingTime(prev => {
                            const newValue = {...prev};
                            
                            if (newValue.seconds > 0) {
                                newValue.seconds = newValue.seconds - 1;
                            } else if (newValue.minutes > 0) {
                                newValue.seconds = 59;
                                newValue.minutes = newValue.minutes - 1;
                            } else if (newValue.hours > 0) {
                                newValue.seconds = 59;
                                newValue.minutes = 59;
                                newValue.hours = newValue.hours - 1;
                            }

                            if (newValue.hours === 0 && newValue.minutes === 0 && newValue.seconds === 0) {
                                if (intervalIdRef.current) {
                                    clearInterval(intervalIdRef.current);
                                }

                                if (onCountdownFinished) {
                                    setTimeout(() => {
                                        onCountdownFinished();
                                    }, 1500);
                                }
                            }

                            return newValue;
                        });
                    }, 1000);
                }
            }
        } else if (endDateDescription) {
            setShowTimer(false);
            setEndDateString(endDateDescription);
        } else {
            setShowTimer(false);
            setEndDateString('');
        }
    }, [endDate, endDateDescription, onCountdownFinished, withoutTime]);

    return showTimer ? (
        <Stack direction='row' className={isLarge ? styles.endTimeContainerLarge : styles.endTimeContainer}>
            <TimerOutlinedIcon className={styles.timerIcon} />
            <Typography className={styles.auctionEndValue} noWrap>{`${padTime(remainingTime.hours)}:${padTime(remainingTime.minutes)}:${padTime(remainingTime.seconds)}`}</Typography>
        </Stack>
    ) : (
        <Stack direction='row' className={isLarge ? styles.endDateContainerLarge : styles.endDateContainer}>
            <Typography className={styles.auctionEndValue}>{endDateString}</Typography>
        </Stack>
    );
}

export default EndTimer;