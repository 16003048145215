import { WatchListing } from "../../../models/WatchListing";
import { UserDetails } from "../../../providers/AuthProvider";

export interface ActionItem {
    id: string;
    name: string;
    action: () => void;
};

export const getActions = (watchDetails: WatchListing, user: UserDetails | undefined): ActionItem[] => {
    const availableActions: ActionItem[] = [];

    // TODO: Implement

    return availableActions;
}

export const getStatus = (watchDetails: WatchListing) => {
    if (!watchDetails.listingStatus && watchDetails.itemStatus) {
        return watchDetails.itemStatus;
    }

    if (watchDetails.listingId && watchDetails.listingStatus) {
        return watchDetails.listingStatus;
    }

    return 'Unknown';
}