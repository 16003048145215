import { Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import CopyrightFooter from '../../footer/CopyrightFooter';
import PersonIcon from '@mui/icons-material/Person';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import {ReactComponent as BiddingHistoryIcon} from '../../../assets/biddingHistory.svg';
import FavoriteIcon from '@mui/icons-material/Favorite';
import styles from './MemberDetails.module.scss';
import PersonalDetails from './PersonalDetails';
import YourListings from './YourListings';
import Favourites from './Favourites';
import BiddingHistory from './BiddingHistory';
import { useMediaQuery } from 'react-responsive';
import { MobileScreenWidth } from '../../../../constants';
import { AuthContext } from '../../../providers/AuthProvider';
import ListingRequests from './ListingRequests';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        sx: { minHeight: '36px', height: '36px' }
    };
}

const MemberDetails = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    
    const isDesktopRef = useRef(isDesktop);
    isDesktopRef.current = isDesktop;
    const {user, login} = useContext(AuthContext);
    const [selectedTab, setSelectedTab] = useState(0);

    const isTenantAdmin = useMemo(() => user?.role.includes('Tenant Admin'), [user]);

    const handleLoginClicked = useCallback(() => {
        login(!isDesktopRef.current);
    }, [login]);

    const onTabChanged = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const getPersonDetailsHeader = useCallback(() => {
        return (
            <Stack direction='row' className={styles.tabHeader}>
                <PersonIcon className={selectedTab === 0 ? styles.headerIconSelected : styles.headerIcon} />
                <Typography className={selectedTab === 0 ? styles.headerTextSelected : styles.headerText}>Personal Details</Typography>
            </Stack>
        )
    }, [selectedTab]);

    const getListingRequestsHeader = useCallback(() => {
        return (
            <Stack direction='row' className={styles.tabHeader}>
                <FormatAlignLeftIcon className={selectedTab === 1 ? styles.headerIconSelected : styles.headerIcon} />
                <Typography className={selectedTab === 1 ? styles.headerTextSelected : styles.headerText}>Client Listing Requests</Typography>
            </Stack>
        )
    }, [selectedTab]);

    const getYourListingsHeader = useCallback(() => {
        return (
            <Stack direction='row' className={styles.tabHeader}>
                <FormatAlignLeftIcon className={selectedTab === 2 ? styles.headerIconSelected : styles.headerIcon} />
                <Typography className={selectedTab === 2 ? styles.headerTextSelected : styles.headerText}>Your Listings</Typography>
            </Stack>
        )
    }, [selectedTab]);

    const getBiddingHistoryHeader = useCallback(() => {
        return (
            <Stack direction='row' className={styles.tabHeader}>
                <BiddingHistoryIcon className={selectedTab === 3 ? styles.biddingHistoryIconSelected : styles.biddingHistoryIcon} />
                <Typography className={selectedTab === 3 ? styles.headerTextSelected : styles.headerText}>Bidding History</Typography>
            </Stack>
        )
    }, [selectedTab]);    

    const getFavouritesHeader = useCallback(() => {
        return (
            <Stack direction='row' className={styles.tabHeader}>
                <FavoriteIcon className={selectedTab === 4 ? styles.headerIconSelected : styles.headerIcon} />
                <Typography className={selectedTab === 4 ? styles.headerTextSelected : styles.headerText}>Favourites</Typography>
            </Stack>
        )
    }, [selectedTab]);

    const tabClasses = {
        standard: styles.tabDropdown,
        select: styles.tabDropdownSelected
    };

    const onTabChange = useCallback((event: SelectChangeEvent) => {
        setSelectedTab(parseInt(event.target.value));
    }, []);

    const getTabContent = useCallback((tabIndex: number) => {
        if (isTenantAdmin) {
            switch(tabIndex) {
                case 0: return <PersonalDetails />;
                case 1: return <ListingRequests />;
                default: return null;
            }
        } else {
            switch(tabIndex) {
                case 0: return <PersonalDetails />;
                case 1: return <YourListings />;
                case 2: return <BiddingHistory />;
                case 3: return <Favourites />;
                default: return null;
            }
        }
    }, [isTenantAdmin]);

    const tabsList = useMemo(() => {
        return isTenantAdmin ? (
            <Tabs value={selectedTab} onChange={onTabChanged} className={styles.tabControl} sx={{ minHeight: '36px', height: '36px' }}>
                <Tab label={getPersonDetailsHeader()} {...a11yProps(0)} disableRipple />
                <Tab label={getListingRequestsHeader()} {...a11yProps(1)} disableRipple />
            </Tabs>
        ) : (
            <Tabs value={selectedTab} onChange={onTabChanged} className={styles.tabControl} sx={{ minHeight: '36px', height: '36px' }}>
                <Tab label={getPersonDetailsHeader()} {...a11yProps(0)} disableRipple />
                <Tab label={getYourListingsHeader()} {...a11yProps(1)} disableRipple />
                <Tab label={getBiddingHistoryHeader()} {...a11yProps(2)} disableRipple />
                <Tab label={getFavouritesHeader()} {...a11yProps(3)} disableRipple />
            </Tabs>
        );
    }, [getBiddingHistoryHeader, getFavouritesHeader, getListingRequestsHeader, getPersonDetailsHeader, getYourListingsHeader, isTenantAdmin, selectedTab]);

    if (!user) {
        return (
            <Stack direction='column' className={styles.loginContainer}>
                <Typography className={styles.loginText}>You must be logged in to access this page:</Typography>
                <Button className={styles.loginButton} variant="contained" disableElevation onClick={handleLoginClicked}>
                    <Typography className={styles.loginButtonText}>Log In</Typography>
                </Button>
            </Stack>
        );
    }

    return isDesktop ? (
        <div className={styles.root}>
            <Box className={styles.tabsContainer}>
                <Box>
                    {tabsList}
                </Box>
                <div className={styles.tabContentContainer}>
                    <TabPanel value={selectedTab} index={0}>
                        <PersonalDetails />
                    </TabPanel>
                    {isTenantAdmin ? (
                        <TabPanel value={selectedTab} index={1}>
                            <ListingRequests />
                        </TabPanel>
                    ) : (
                        <>
                        <TabPanel value={selectedTab} index={1}>
                            <YourListings />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2}>
                            <BiddingHistory />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={3}>
                            <Favourites />
                        </TabPanel>
                        </>
                    )}
                </div>
            </Box>

            <CopyrightFooter />
        </div>
    ) : (
        <div className={styles.rootMobile}>
            <Stack direction='column' className={styles.tabsContainerMobile}>
                <Stack direction='row'>
                    {isTenantAdmin ? (
                        <Select id='sortBySelect' classes={tabClasses} variant="standard" value={`${selectedTab}`} onChange={onTabChange}>
                            <MenuItem value={'0'}>{getPersonDetailsHeader()}</MenuItem>
                            <MenuItem value={'1'}>{getListingRequestsHeader()}</MenuItem>
                        </Select>
                    ) : (
                        <Select id='sortBySelect' classes={tabClasses} variant="standard" value={`${selectedTab}`} onChange={onTabChange}>
                            <MenuItem value={'0'}>{getPersonDetailsHeader()}</MenuItem>
                            <MenuItem value={'1'}>{getYourListingsHeader()}</MenuItem>
                            <MenuItem value={'2'}>{getBiddingHistoryHeader()}</MenuItem>
                            <MenuItem value={'3'}>{getFavouritesHeader()}</MenuItem>
                        </Select>
                    )}
                </Stack>

                <Stack direction='row' className={styles.tabContentMobile}>
                    {getTabContent(selectedTab)}
                </Stack>
            </Stack>

            <CopyrightFooter />
        </div>        
    );
}

export default MemberDetails;