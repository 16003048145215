import { format } from "date-fns"

export const getDisplayDate = (date?: Date|string, dateDescription?: string) => {
    if (date) {
        try {
            const formatDate = dateDescription === "WithTime" ? 'dd-MMM-yyyy hh:mm a' : 'dd-MMM-yyyy';
            if (typeof(date) === 'string') {
                return format(new Date(date), formatDate);
            }
            
            return format(date, formatDate);
        } catch (error) {
            console.log('Invalid date', date);
            return '';
        }
    } else if (dateDescription) {
        return dateDescription;
    }

    return '';
}